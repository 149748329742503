<div class="bg-kz-layout-featured">
  <div class="border-bottom p-2">
    <div class="font-medium flex flex-row gap-1 items-center">
      {{ hotel.hotelName }}
      <app-kz-stars class="pb-1" [stars]="hotel.hotelClass" />
    </div>
    <div class="text-secondary">{{ hotel.cityName }}, {{ hotel.districtName }}</div>
  </div>
  <div class="details-list p-2">
    <div>
      <mat-icon svgIcon="fill-calendar" color="primary" />
      {{ hotel.checkInDate | date }} — {{ hotel.checkOutDate | date }} ({{ hotel.nights }} {{ 'Nights' | translate }})
    </div>
    @if (hotel.roomCategoryName) {
      <div>
        <mat-icon svgIcon="fill-bed" color="primary" />
        {{ hotel.roomCategoryName }},
        @if (hotel.placementTypeName) {
          {{ hotel.placementTypeName }}
        }
      </div>
    }
    <div class="flex">
      @if (hotel.mealPlan) {
        <div>
          <mat-icon svgIcon="fill-nutrition" color="primary" />
          {{ hotel.mealPlan }}
        </div>
      }
      @if (hotel.adults) {
        <div>
          <mat-icon svgIcon="user" color="primary" />
          {{ hotel.adults }} {{ 'Adults' | translate }}
        </div>
      }
      @if (hotel.children) {
        <div>
          <mat-icon svgIcon="child" color="primary" />
          {{ hotel.children }} {{ 'Children' | translate }}
          @if (hotel._extraParams?.ages) {
            (
            {{ hotel._extraParams?.ages?.join(', ') }}
            )
          }
        </div>
      }
    </div>
  </div>
</div>
