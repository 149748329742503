import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IPackageHotel } from '@appTypes/redefined-types';
import { TranslateModule } from '@ngx-translate/core';
import { KzStarsComponent } from '@shared/components/kz-stars/kz-stars.component';

@Component({
  selector: 'app-kz-mobile-hotel-card',
  standalone: true,
  imports: [KzStarsComponent, TranslateModule, MatIconModule, CommonModule],
  templateUrl: './kz-mobile-hotel-card.component.html',
  styleUrl: './kz-mobile-hotel-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KzMobileHotelCardComponent {
  @Input() hotel!: IPackageHotel;
}
