import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { KzStarsComponent } from '@shared/components/kz-stars/kz-stars.component';
import { TranslateModule } from '@ngx-translate/core';
import { IPackageHotel } from '@appTypes/redefined-types';

@Component({
  selector: 'app-kz-desktop-hotel-card',
  standalone: true,
  imports: [CommonModule, MatIconModule, KzStarsComponent, TranslateModule],
  templateUrl: './kz-desktop-hotel-card.component.html',
  styleUrl: './kz-desktop-hotel-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KzDesktopHotelCardComponent {
  @Input() hotel!: IPackageHotel;
}
